import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private buttonClickSource = new Subject<void>();
  private triggerOpenSource = new Subject<void>();

  buttonClick$ = this.buttonClickSource.asObservable();
  triggerOpen$ = this.triggerOpenSource.asObservable();

  emitButtonClick() {
    this.buttonClickSource.next();
  }

  emitTriggerOpen() {
    this.triggerOpenSource.next();
  }
}
