import { Component } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { takeUntil } from 'rxjs';
import { BaseComponent } from './components/base/base.component';
import { CommonService } from './services/common.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent {
  isMenuOpen: boolean = false;
  isAuthenticated: boolean = false;
  isModal: boolean = false;

  constructor(
    public commonService: CommonService,
    private oktaStateService: OktaAuthStateService,
    private sharedService: SharedService
  ) {
    super(commonService);
    // While we are not authenticated for any reason, hide the app entirely
    this.oktaStateService.authState$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.isAuthenticated = res.isAuthenticated as boolean;
      });
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  hmeOpen() {
    this.sharedService.emitButtonClick();
  }

  triggerOpen() {
    this.sharedService.emitTriggerOpen(); // Call the new method
  }
}
